import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

const TevuAtsiliepimaiPage = () => {
  const pageTitle = 'Tėvų atsiliepimai'
  const pageCards = [
    {
      title: 'Mėtos mama',
      body: (
        <span>
          Norime iš visos širdies padėkoti už jaukius bei šiltus ,,antruosius
          namus“ Mėtukui. Mėta buvo pirmasis vaikas jūsų darželyje, o Pėdutės
          buvo pirmasis darželis Mėtai, todėl Jūsų nepamiršime niekada...Ačiū už
          rankos palaikymą, kai širdutei buvo neramu užmiegant. Ačiū už viską,
          kas atrodo taip kasdieniška, tačiau yra neįkainojama.
        </span>
      )
    },
    {
      title: 'Lauryno mama',
      body: (
        <span>
          Ačiū, kad susikūrėte, ačiū, kad priėmėte Lauryną tokį, koks jis yra ir
          vedėte pirmyn. Išeiname į mokyklą....ir gaila, bet kartu ir smagu, nes
          buvo gera. Nesuskaičiuosim visko, ko išmoko Laurynas, bet tikrai daug.
          Tai pirmas mūsų darželis, tikras, geras, nuostabus, toks, koks turi
          būti. Gal būt Jums patiems ir norėsis tobulėti, bet mums Jūs jau dabar
          toks darželis esate. Tiesiog būkite, mylėkite vaikus, auklėkite juos,
          jums viskas gerai sekasi!
        </span>
      )
    },
    {
      title: 'Ugniaus mama',
      body: (
        <span>
          Esame labai laimingi, kad Ugnius pradėjo lankyti būtent šį privatų
          darželį. Darželis įsikūręs ramioje, gražioje Antežerių gyvenvietėje,
          pati aplinka be galo graži, didžiulis kiemas. Kai susipažinome su
          vadovėmis supratome, kad atiduosime savo vaiką į labai geras rankas.
          Auklėtojos taipogi yra be galo mylinčios ir atsidavusios savo darbui,
          kiekvieną vakarą mielai papasakodavo kaip Ugniui darželyje tądien
          sekėsi, ką veikė. Kiekvieną penktadienį gaudavome į el.p laiškelį nuo
          auklėtojos su visos savaitės veiklų aprašymu ir nuotraukytėmis. Labai
          daug nuostabių pirmų kartų Ugniaus gyvenime įvyko darželyje. Pirmieji
          žodžiai buvo ištarti jau lankant darželį, Ugnius išmoko būti grupės
          nariu, dalintis, išmoko būti kantresnis. Esame labai dėkingi šiam
          darželiui!
        </span>
      )
    },
    {
      title: 'Beno mama',
      body: (
        <span>
          Ačiū, kad parodėte, koks nuostabus ir streso mažyliui nekeliantis gali
          būti darželis. Ačiū už individualų dėmesį kiekvienam ,,Mūsų pėdučių“
          gyventojui.
        </span>
      )
    },
    {
      title: 'Anytės mamytė',
      body: (
        <span>
          Mes darželį "Mūsų pėdutės" lankėme beveik visus mokslo metus ir tai
          buvo pirmoji ugdymo įstaiga mano mažai 1,8 m dukrytei. "Mūsų pėdutės"
          kolektyvui noriu padėkoti už pagalbą vaikui ir tėveliams adaptuojantis
          , už patarimus ir palaikymą. Vaikas visus metus jautėsi puikiai,
          darėsi savarankiškas, mokėsi įvairių darbelių ir naujų veiklų. Puiku,
          kad organizuojate šventes, papildomus užsiėmimus, įtraukiate vaikučius
          į veiklą lauke (kaip sodas, jo priežiūra). Darželio stiprioji pusė yra
          jo vieta, puikus didelis namas ir graži aplinka lauke, pakankamai
          vietos vaikams, žaisti, bėgioti, ilsėtis ir miegoti. Dar atskirai
          norime padėkoti auklėtojai - už supratingumą, patarimus, kantrybę ir
          bendrai- už profesionalumą dirbant su tokiais mažais vaikučiais.
        </span>
      )
    },
    {
      title: 'Jokūbo mama',
      body: (
        <span>
          Atvykome pas Jus turbūt patys mažiausi, su čiulptuku, migduku, keista
          ropojimo poza ir didelėmis akims. Išvykstame išaugę, plepūs,
          žingeidūs.... Visada mums liksite labai brangia vieta, kurioje
          praleidome beveik metus laiko. Čia pradėjome savarankiškai dėlioti
          pėdutės, draugauti, kalbėti, savarankiškai valgyti. Niekada
          nepamiršiu, kaip vieną kartą grįžtant iš darželio Jokūbas visą kelią
          kartojo auklėtojos vardą. Tai buvo bene pirmas jo aiškus žodis! Dėkoju
          Jums už visus pirmus kartus, už rūpestį, šilumą ir kūrybiškumą,
          profesionalumą. Stipriai apkabiname ir linkime daug skardaus juoko,
          toliau laimingai augti bei rinkti šypsenas iš vaikų ir laimingų tėvų.
        </span>
      )
    },
    {
      title: 'Rusnės tėveliai',
      body: (
        <span>
          Rusnei, kai atėjome lankyti Jūsų darželį, buvo pirmas didelis iššūkis.
          Dėkui Jums už kantrybę ir dėmesį. Tai mūsų pirmas darželis, Rusnei jis
          tapo antraisiais namais, ji čia atrado draugų, ji suprato, kad
          darželyje yra saugu, ramu ir kad gali likti be mamos ar tėčio.
          Dėkojame už jaukią aplinką, rūpestį ir dėmesį, už nuoširdumą ir
          kantrybę.
        </span>
      )
    },
    {
      title: 'Evano tėveliai',
      body: (
        <span>
          ...Iš širdies norėjosi pasveikinti už jūsų šilumą ir rūpestį, kuriuos
          suteikiate vaikučiams :) Didžiulis ačiū auklėtojai už jos gerumą,
          išradingumą, švelnumą ir didžiulį rūpestį! Didžiulis ačiū asistentei
          už jos atsidavimą savo darbui, už spinduliuojančią meilę vaikams :) Ir
          žinoma, didžiulis ačiū Jums, darželio įkūrėjoms, turite didžiuotis, ką
          sukūrėte! Geresnio darželio ir įsivaizduoti negalėtume :) Ačiū ačiū
          ačiū...
        </span>
      )
    },
    {
      title: 'Benedikto mama',
      body: (
        <span>
          Pačiam <i>kiečiausiam</i> darželiui pačių <i>kiečiausių</i> metų!
          Aukite ir būkite stiprūs! O kiekvienai
          <i>kiečiausio</i> darželio
          <i>kiečiausiai</i> komandos narei linkime paprastos ir tikros
          moteriškos laimės! Labai dėkojame už visas šventines iniciatyvas, ta
          patirtis, kai vaikas pasakoja, tikrąją šio žodžio prasme, gaudydamas
          orą, yra nepamirštama. Ir mūsų Benediktas uždainavo, būtent per
          Kalėdas, pas senelius. Toks mažas dar, o taip taupėsi ir atsiskleidė
          pačiais tinkamiausiais momentais. Tikras stebuklas ir tai, kai
          nesiuvančios mamos ima siūti :D
        </span>
      )
    },
    {
      title: 'Benedikto šeima',
      body: (
        <span>
          Ačiū už metus Draugystės! Šiandien norisi padėkoti už viską,
          pasidžiaugti ir tik plačia širdimi. &lt;...&gt; Ir netikėk tu likimu.
          Taip ilgai ieškojome savo namų. Ir kai pagaliau juos įsigijome, tik
          ant juoko sakau pažiūrėsiu, gal šitame mūsų kaime yra darželis. Ir
          prašau - yra! :) Tada pirmu numeriu žaidė atstumas (gal kiek ne
          supertėviška, bet taip buvo :)), kad būtų kuo arčiau namų. Su mažu
          atstumu, gavome ir visą kitą gėrį :) Kartais, kai pradedame vos
          atgaudami kvapą pasakoti, kad Mūsų darželyje taip gerai, ir ten
          dėmesys, ir ten dėmesys, e. laiškai penktadieniais, paskaitos, daržas,
          spektakliai, vaiko nuotaikų pulsas ir t.t. ir pan., dažniausiai
          sulaukiame replikų: "Ai taigi privatus darželis, Jūs mokate pinigus,
          tai dėl to". Nu niekada, niekada nepatikėsime, kad pinigai gali
          daugiau nei širdis, kad graži lova, gali daugiau nei pasaka prieš
          miegą, kad super kieta žaislinė mašina, gali daugiau, nei žmogus,
          padedantis atsistoti pargriuvus, arba draugas su kuriuo bėgdamas
          lenktyniauji, kad labai brangūs indai, gali daugiau nei ranka,
          padedanti pavalgyti, kad gražus kilimas, gali daugiau, nei
          stebuklingas vėjas užsigavus, kad gražios sienos gali daugiau nei
          ištiesta servėtėlė nuo ašarų, ar šaltuko bėgančiai nosytei. Žinoma
          negalima visiškai ignoruoti tų popierėlių su skaičiais, tačiau vieni
          jie negali daugiau nei širdis. Žavi Jūsų gebėjimas išlikti tikromis ir
          motyvuotomis visų tų ūkinių, finansinių reikalų, įvairių įstaigų
          reikalavimų makalynėje. &lt;...&gt; Ar kada nors skaičiavote, kiek
          gerų darbų padarote kiekvienam vaikui per vieną dieną? Kiek visko
          išmokote? Ne??? Tai paskaičiuokite ir neliks abejonių, kokį nuostabų
          ir didelį darbą darote! Kokius stebuklus kasdien be jokių burtų
          lazdelių kuriate. Kiekviena didžiuokitės savimi iki begalybės!
        </span>
      )
    }
  ]
  return <MyPage pageTitle={pageTitle} pageType="card" pageCards={pageCards} />
}

export default TevuAtsiliepimaiPage
